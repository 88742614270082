$product-variations-carousel-link-height: rem-calc(68);
$product-variations-carousel-link-min-width: rem-calc(100);
$product-variations-carousel-link-color: color(organism-background);
$product-variations-carousel-link-font: 400 #{rem-calc(14)} / rem-calc(20) $font-primary;
$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-border: 1px solid color(border);
$product-variations-carousel-link-padding: rem-calc(0 10 0 20);
$product-variations-carousel-link-selected-border: 1px solid color(primary);
$product-variations-carousel-link-selected-color: color(organism-background);
$product-variations-carousel-link-selected-font: 400 #{rem-calc(14)} / rem-calc(20) $font-primary;
$product-variations-carousel-link-align-items: normal;
$product-variations-carousel-link-border-radius: rem-calc(0);
$product-variations-carousel-link-hover-background: rgba(color(primary), 0.1);

//price
$product-variations-carousel-price-color: color(text-element);
$product-variations-carousel-price-font-weight: 300;

// Disabled state
$product-variations-carousel-link-disabled-opacity: 0.3;
$product-variations-carousel-link-disabled-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-disabled-color: $product-variations-carousel-link-color;
$product-variations-carousel-link-disabled-border: $product-variations-carousel-link-border;

@import "@lora/05-components/product/product-variations-carousel";
