$search-refinement-title-expanded-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-medium;
$search-refinement-title-font: 300 #{rem-calc(12)} / #{rem-calc(20)} $font-primary;
$search-refinement-title-font--large: $search-refinement-title-font;
$search-refinement-content-margin--large: rem-calc(20 0 10);
$search-refinement-title-padding: rem-calc(15 20);
$search-refinement-title-padding--large: rem-calc(15 40 15 20);
$search-refinement-border-bottom: 1px solid color(border);
$search-refinement-applied-qty-border-radius: 0;
$search-refinement-applied-qty-background: color(dark);
$search-refinement-applied-qty-font: #{rem-calc(12)} / #{rem-calc(16)} $font-primary;
$search-refinement-applied-qty-margin: rem-calc(0 0 0 50);
$search-refinement-applied-qty-size: rem-calc(16);
$search-refinement-title-expanded-color: color(organism-background);
$search-refinement-applied-values-font: 300 #{rem-calc(12)} / rem-calc(16) $font-secondary;
$search-refinement-title-after-content-color: color(text);
$search-refinement-title-expanded-border-left: rem-calc(2) solid color(text);
$search-refinement-content-wrapper-background-color: color(secondary);
$search-refinement-title-expanded-padding: rem-calc(18);
$search-refinement-content-background--medium-down: color(secondary);
$search-refinement-content-padding--medium-down: rem-calc(15 0 0);
$search-refinement-content-range-padding--medium-down: rem-calc(30 15 15);

@import "@lora/05-components/search/search-refinement";
