@import "@lora/03-base/colors";
$global-category: 'global' !default;
$palette-custom: (
    light:     #fff,
    dark:      #000,
    transparent: transparent
);

$dark-mode-colors: (
    background:           #f4f4f4,
    text:                 #000,
    border:               #fff,
);

$semantic-colors-custom: (
    primary:             #009ee2,
    primary-active:      #525252,
    secondary:           #fff,
    text:                #000,
    text-secondary:      #4e4e4e,
    text-primary:        #5f5f5f,
    text-element:        #8c8c8c,
    text-organism:       #333,
    success:             #276027,
    warning:             #ffae00,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #8c8c8c,
    global-background:   #f4f4f4,
    secondary-background: #f3f3f3,
    primary-background:   #f9f9f9,
    text-background:      #e6f5fc,
    organism-background: #333,
    info-background:    #e6ecf4,
    border:              #d8d8d8,
    info:                #d8d8d8,
    highlight-secondary: #05408d
);

$semantic-colors-custom-dermablend: (
    primary:             #116099,
    primary-active:      #dcc7b4,
);

$semantic-colors-dermablend: map-extend($semantic-colors-custom, $semantic-colors-custom-dermablend);

@if $global-category == 'dermablend' {
    $semantic-colors: map-extend($semantic-colors-default, $semantic-colors-dermablend);
}
@else {
    $semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
}

$palette: map-extend($palette-default, $palette-custom);
