$carousel-arrow-sizes: (
    default: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(40),
        arrow-icon-height: rem-calc(40),
        arrow-icon: arrow-icon
    ),
    small: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(25),
        arrow-icon-height: rem-calc(40),
        arrow-icon: arrow
    ),
    medium: (
        arrow-width: rem-calc(27),
        arrow-height: rem-calc(30),
        arrow-icon-width: rem-calc(25),
        arrow-icon-height: rem-calc(20),
        arrow-icon: arrow
    )
);

$carousel-arrow-theme: (
    light: (
        background: color(light),
        color: color(dark),
        box-shadow: rem-calc(0 0 4) color(organism-background)
    )
);

$carousel-arrow-color: color(dark);
$carousel-arrow-border-radius: 50%;
$carousel-pagination-bullet-color: color(disabled);
$carousel-pagination-bullet-size: rem-calc(5);
$carousel-pagination-bullet-horizontal-margin: rem-calc(1.5);
$carousel-pagination-bullet-active-color: color(dark);
$carousel-pagination-bullet-active-scale: 1;
$carousel-pagination-bullet-size-default--large: rem-calc(9);
$carousel-pagination-bullet-horizontal-margin-default--large: rem-calc(5);
$carousel-pagination-bullet-active-scale--large: 1.555;
$carousel-pagination-bullet-size: rem-calc(9);
$carousel-pagination-margin: 0;
$carousel-arrow-aside-pagination-prev-left: rem-calc(4);
$carousel-arrow-aside-pagination-next-right: rem-calc(4);

// Pagination space-reserve height
$carousel-pagination-space-reserve-small-height: rem-calc(21);

@import "@lora/05-components/carousel";
