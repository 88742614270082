$product-offer-background: color(global-background);
$product-offer-padding: 0;
$product-offer-icon-width--large: rem-calc(170);
$product-offer-icon-padding: 0;
$product-offer-description-padding: rem-calc(0 20);
$product-offer-description-padding--large: rem-calc(35 30 20);

// Title
$product-offer-title-font-weight: 500;
$product-offer-title-font: #{rem-calc(14)} / 1.45 $font-primary;
$product-offer-title-text-transform: uppercase;
$product-offer-title-color: color(dark);

// Description
$product-offer-description-font: #{rem-calc(14)} / 1.45 $font-primary;
$product-offer-description-color: color(organism-background);

@import "@lora/05-components/product/product-offer";
