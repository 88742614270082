$selected-products-title-font: 300 #{rem-calc(12)} / 1 $font-primary;
$selected-products-title-font--large: 500 #{rem-calc(14)} / 1.5 $font-primary;
$selected-products-title-color: color(organism-background);
$selected-products-item-border: 2px solid color(dark);
$selected-products-item-border--large: $selected-products-item-border;
$selected-products-item-active-border: $selected-products-item-border;
$selected-products-item-active-border--large: $selected-products-item-border;
$selected-products-item-remove-icon-color: color(dark);
$selected-products-item-remove-icon-hover-color: color(primary);
$selected-products-title-margin: rem-calc(0) auto rem-calc(16);
$selected-products-total-background: color(light);
$selected-products-total-background--large: $selected-products-total-background;
$selected-products-total-font: #{rem-calc(12)} / 1 $font-secondary;
$selected-products-total-font--large: #{rem-calc(14)} / 1.5 $font-secondary;
$selected-products-total-padding: rem-calc(19);
$selected-products-total-padding--large: rem-calc(17);

@import "@lora/05-components/selected-products";
