$product-sticky-bar-title-text-transform: uppercase;
$product-sticky-bar-title-font-weight: 500;
$product-sticky-bar-title-font-size: rem-calc(14);
$product-sticky-bar-description-color: color(text-secondary);
$product-sticky-bar-item-image-border: none;
$product-sticky-bar-availability-label-color: color(text);
$product-sticky-bar-availability-label-text-transform: none;
$product-sticky-bar-item-margin: rem-calc(0 10 0 0);

@import "@lora/05-components/product/product-sticky-bar";
