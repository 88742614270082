$product-buy-now-image-max-width: rem-calc(200) !default;
$product-buy-now-image-height: rem-calc(100) !default;
$product-buy-now-image-padding: rem-calc(10) !default;

@mixin apaclayer-component-product-buy-now {
    .c-buy-now__image {
        max-width: $product-buy-now-image-max-width;
        height: $product-buy-now-image-height;
        padding: $product-buy-now-image-padding;
    }
}
