$product-detail-image-badge-large-top: rem-calc(15);
$product-detail-image-badge-left--large: rem-calc(5);
$product-detail-image-badge-left: rem-calc(16);
$product-detail-image-mosaic-services-secondary-padding: 0;
$product-detail-image-mosaic-services-spacing: rem-calc(2);
$product-detail-image-mosaic-services-margin: rem-calc(20 0 0);

// Horizontal carousel
$product-detail-image-alternatives-horizontal-max-width--large: rem-calc(516);
$product-detail-image-alternatives-horizontal-margin--large: rem-calc(10) auto;

// 360 View
$product-detail-image-view360-top: rem-calc(68);
$product-detail-image-view360-right: rem-calc(25);

// Image Zoom
$product-detail-image-zoom-display: none;
$product-detail-image-zoom-display--large: block;

@import "@lora/05-components/product/product-detail-image";
