$font-primary: "Locator-Regular", sans-serif;
$font-secondary: "Locator-Light", sans-serif;
$font-primary-medium: "Locator-Medium", sans-serif;

$font-list: (
    ('Locator-Light', 'Locator-Light', 300, normal),
    ('Locator-Regular', 'Locator-Regular', 400, normal),
    ('Locator-Medium', 'Locator-Medium', 500, normal)
);

@import "@lora/font-settings";
