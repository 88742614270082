$simple-navigation-background: color(global-background);
$simple-navigation-font: 300 #{rem-calc(12)} / rem-calc(16) $font-secondary;
$simple-navigation-link-font: $simple-navigation-font;
$simple-navigation-color: color(organism-background);
$simple-navigation-link-padding: rem-calc(12 23);
$simple-navigation-item-line-height: rem-calc(16);
$simple-navigation-anchors-link-active-color: color(organism-background);
$simple-navigation-anchors-link-active-font-weight: 500;

@import "@lora/05-components/simple-navigation";
