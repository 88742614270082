$video-asset-info-margin--large: rem-calc(12 0 0);
$video-asset-info-name-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$video-asset-info-name-font--large: $video-asset-info-name-font;
$video-asset-info-name-text-transform: uppercase;
$video-asset-info-name-text-transform--large: uppercase;
$video-asset-info-name-margin--large: rem-calc(0 0 5);
$video-asset-info-description-color--large: color(text);
$video-asset-info-description-font: 300 #{rem-calc(12)} / 1.34 $font-primary;

@import "@lora/05-components/video-asset";
