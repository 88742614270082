$scrollable-arrow-sizes: (
    default: (
        arrow-width: rem-calc(48),
        arrow-height: rem-calc(48),
        arrow-icon-width: rem-calc(15),
        arrow-icon-height: rem-calc(24),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(35),
        arrow-height: rem-calc(35),
        arrow-icon-width: rem-calc(15),
        arrow-icon-height: rem-calc(25),
        arrow-icon: arrow
    )
);

$scrollable-arrow-theme: (
    light: (
        color: color(text),
    ),
);

$scrollable-fade-area-width: 0;
$scrollable-arrow-color: color(dark);
$scrollable-arrow-background: color(light);
$scrollable-arrow-border-radius: 50%;

@import "@lora/05-components/scrollable";
