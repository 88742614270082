$video-library-item-name-font: #{rem-calc(12)} / rem-calc(16) $font-secondary;
$video-library-item-name-font--large: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$video-library-item-name-text-transform: none;
$video-library-item-name-text-transform--large: uppercase;
$video-library-playlist-border-top-color: 1px solid color(organism-background);
$video-library-item-border-radius--medium-down: 0;
$video-library-item-width--medium-down: rem-calc(270);
$video-library-item-background--medium-down: color(organism-background);
$video-library-item-name-color: color(text-secondary);
$video-library-item-name-color--large: color(dark);
$video-library-item-name-margin--large: rem-calc(0 0 8);
$video-library-item-active-border: none;
$video-library-item-description-max-lines--large: 2;
$video-library-item-description-color--large: color(text-secondary);
$video-library-item-description-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$video-library-item-margin--large: rem-calc(0 0 20 0);
$video-library-playlist-title-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary-medium;
$video-library-item-image-margin--large: rem-calc(0 34 0 0);
$video-library-item-image-margin: rem-calc(0 19 0 0);
$video-library-item-margin--medium-down: rem-calc(0 5 0 0);
$video-library-item-background--medium-down: color(primary-background);
$video-library-playlist-title-margin: rem-calc(0 0 5 0);

@import "@lora/05-components/video-library";
