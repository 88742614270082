$back-to-parent-background: none;
$back-to-parent-padding: rem-calc(15 0 15 10);
$back-to-parent-color: color(organism-background);
$back-to-parent-icon-height: rem-calc(10);
$back-to-parent-icon-width: rem-calc(4);
$back-to-parent-icon-margin: rem-calc(0 10 2 0);

// Border
$back-to-parent-border-top: 0;
$back-to-parent-border-bottom: 0;

// Secondary
$back-to-parent-secondary-font: #{rem-calc(12)} / 1 $font-primary;
$back-to-parent-secondary-font--large: $back-to-parent-secondary-font;
$back-to-parent-secondary-background--large: color(global-background);
$back-to-parent-secondary-padding: rem-calc(22 0);
$back-to-parent-secondary-padding--large: rem-calc(15 0 15 10);
$back-to-parent-icon-width: rem-calc(4);
$back-to-parent-text-transform: uppercase;
$back-to-parent-font: 500 #{rem-calc(12)} / 1 $font-primary;
$back-to-parent-secondary-text-transform: none;

@import "@lora/05-components/back-to-parent";
