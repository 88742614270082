/// Font color for buttons.
/// @type Color
$button-color: color(light);
$button-hover-color: color(dark);
$button-hover-background: color(light);
$button-hover-border: 1px solid color(primary-active);
$button-font-family: $font-primary;
$button-loading-icon-hover-color: color(dark);
$button-search-height: rem-calc(50);
$button-icon-only-height: rem-calc(50);

// Button with price below the text
$button-row-2-price-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-medium;
$button-row-2-text-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-medium;
$button-row-2-padding: rem-calc(8 12);

// Geolocation button
$button-geolocation-icon-hover-color: color(primary);

/// Primary button color.
/// @type Map
$button-primary: (
    background: color(primary),
    background-hover: color(secondary),
    color: color(light),
    color-hover: color(dark)
);

/// Secondary button color.
/// @type Map
$button-secondary: (
    background: color(secondary),
    background-hover: color(secondary),
    color: color(dark),
    color-hover: color(dark),
    border: 1px solid color(border),
    border-hover: 1px solid color(dark)
);

/// Default font size for buttons.
/// @type Number
$button-font-size: rem-calc(12);
$button-font-weight: 500;
$button-padding: rem-calc(18 9);
$button-letter-spacing: rem-calc(0.8);
$button-social-icon-left: false;
$button-social-icon-text-transform: uppercase;
$button-social-icon-margin: rem-calc(2);
$button-social-icon-size: rem-calc(26);
$button-social-icon-color: color(light);
$button-print-font-weight: 400;
$button-print-icon-hover-color: color(primary);

$button-social-list: (
    facebook: (
        icon: facebook-2,
        icon-color: color(light),
        icon-color-hover: color(dark),
        background: color(primary),
        background-hover: color(light),
        color: color(light),
        color-hover: color(dark),
        border-hover: 1px solid color(dark)
    ),
    google: (
        icon: google-2,
        icon-color: color(light),
        icon-color-hover: color(dark),
        background: color(primary),
        background-hover: color(light),
        color: color(light),
        color-hover: color(dark),
        border-hover: 1px solid color(dark)
    )
);

@import "@lora/05-components/button";
