$accordion-title-padding: rem-calc(16 0 15);
$accordion-icon-color: color(dark);
$accordion-title-text-transform: uppercase;
$accordion-title-text-transform--large: none;
$accordion-item-collapsed-first-border-top: 1px solid color(border);
$accordion-title-secondary-collapsed-font: 500 #{rem-calc(12)} / #{rem-calc(16)} $font-primary-medium;
$accordion-title-color: color(organism-background);
$accordion-title-secondary-padding: rem-calc(18 50 18 20);
$accordion-title-secondary-padding--large: $accordion-title-secondary-padding;
$accordion-icon-width: rem-calc(10);
$accordion-title-secondary-background-color-expanded: color(transparent);
$accordion-title-secondary-color-expanded: color(organism-background);
$accordion-title-font: 500 #{rem-calc(14)} / 1.25 $font-primary-medium;
$accordion-content-font-size: rem-calc(14);

@import "@lora/05-components/accordion";
